<template>
  <div>
    <S1 />
    <S2 />
    <S3 />
    <S4 />

  </div>
</template>

<script>
export default {
  name: "About",
  components: {
    S1: () => import("../components/aboutus/s1.vue"),
    S2: () => import("../components/aboutus/s2.vue"),
    S3: () => import("../components/aboutus/s3.vue"),
    S4: () => import("../components/aboutus/s4.vue"),
  },
};
</script>

<style scoped></style>
